.map-wrap {
  position: relative;
  width: 100%;
 /*height: calc(100vh - 77px); /* calculate height of the screen minus the heading */
 height: 100vh;
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
}