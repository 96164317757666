.heading {
  margin: 0;
  padding: 0px;
  background-color: black;
  color: white;
  text-align: center;
}

.heading > hi {
  padding: 20px;
  margin: 0;
}
